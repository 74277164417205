import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';
import AppButtons from '../components/AppButtons';
import Seo from '../components/Seo';


const AppPage = () => {

  const { t } = useTranslation();

  return (
    <Layout >
      <Seo
        title={t("PlatformTitle")}
        description={t("Platform")}
      />
      <div className="max-w-md mx-auto h-full justify-center items-center self-center flex rounded-xl">
        <AppButtons />
      </div>
    </Layout>
  )
}

export default AppPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;